<template>
  <div class="lunbo-wrap"  >
    <div class="swiper-container">
      <div class="swiper-wrapper"  >
        <div class="swiper-slide" v-for="(item,index) in dragList" :key="index" @click="dian(item,index)"
            >
          <img :src="item.img" alt="">

        </div>

      </div>


      <!-- 如果需要分页器 -->
      <div v-if="dragList.length>7" class="swiper-pagination"></div>

      <!-- 如果需要导航按钮 -->
      <div  class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>

  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import {mapState} from 'vuex'

export default {
  props: {
    dragList: {type: Array}
  },
  components: {},
  name: 'TabAndBanner',
  data() {
    return {
      current: 0,
      isShow: false,
      swiperList: []
    }
  },
  methods: {
    dian(item, index) {
      this.current = index;
      this.$emit("clickBoxBottom", item);
    },
    rightVideo() {
      this.current = (this.current + 1) % this.dragList.length;
      this.dian(this.dragList[this.current], this.current);
    },
    leftVideo() {
      if ((this.current - 1) < 0) {
        this.current = this.dragList.length - 1

      } else {
        this.current = (this.current - 1) % this.dragList.length;
      }
      this.dian(this.dragList[this.current], this.current);
    },
    carouselScroll() {
      var mySwiper = new Swiper('.swiper-container', {
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        //设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        //自动轮播
        // autoplay: {
        //   // delay: 10000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false,
        // },

        slidesPerView: 6,
        spaceBetween: 1,
        //开启循环模式
        loop: false,
        observeParents: true,   //必须加入
        observer: true, //必须加入
      });
    },

  },

  mounted() {
    this.$nextTick(function () {  //l轮播图初始化函数carouselScroll一定要放在$nextTick回调中执行
      if(this.dragList){
        this.carouselScroll();
      }
    })
  }
}
</script>
<style lang="scss" scoped>

.videoBoxTitle {
  font-size: 16px;
  color: #fff;
  margin: 14px 0;
  font-weight: 500;
}

.lunbo-wrap {
  width: 100%;
  margin: 0 auto;

  .swiper-container {
    .swiper-button-prev, .swiper-button-next {
      top: 40%;
      color: #FFFFFF;

    }

    .swiper-slide {
      display: block;
      position: relative;
      cursor: pointer;
      width: 168px;


      img {
        width: 168px;
        height: 100px;
      }


    }

    .active {
      img {
        border: 2px solid #fd555f;

      }

      p {
        color: #fd555f;
      }
    }

    .swiper-pagination-bullet {
      width: 80px;
      height: 10px;

      border-radius: 0;
    }
  }
  .swiper-button-prev, .swiper-button-next{
    color: #fff;
    background: #a3a6a3;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 14px;
  }

}
.swiper-slide {
  width: 180px !important;
}

</style>


