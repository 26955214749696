<template>
  <div style="width: 1100px;margin-top: 20px;">
    <!-- 通告栏 -->
    <div style="width: 1100px;
    height:60px;
    background: #e0e0e0">
      <br>
      <circulars/>
    </div>
    <!-- 顶部  -->
    <div style="margin-top: 10px; display: flex">
      <!-- <el-row :gutter="10"> -->
      <!-- <el-col :span="5"> -->
      <div style="width: 216px">
        <el-card
            style="height: 400px; overflow-y: scroll; border: 1px solid #0080ff"
        >
          <div
              style="margin-bottom: 2px; display: flex"
              v-for="item in fileTypes"
          >
            <el-link
                style="text-align: left; width: 100%; justify-content: left"
                @click="jumpFile(item.id)"
            >
              <span style="font-size: 14px; text-align: left; line-height: 2">{{
                  item.name
                }}</span>
            </el-link>
            <p class="el-icon-arrow-right"></p>
          </div>
        </el-card>
      </div>

      <div v-if="middleImg.length > 0" style="margin: 0 11px">
        <swiper :lunboList="middleImg"></swiper>
      </div>

      <div style="height: 210px;width: 358px">
        <el-image
            v-for="item in rightImg"
            style="width: 100%; height:95%;cursor: pointer;"
            :src="item.img"
            @click="openNewUlr(item.hyperlink)"
        ></el-image>
      </div>
      <!-- </el-col> -->
      <!-- </el-row> -->
    </div>

    <!-- 中间  -->
    <div>
      <news/>
    </div>

    <!-- 底部走进 -->
    <!--    <div class="footerBox" style="margin-top: 10px">-->
    <!--      <el-row :gutter="10">-->
    <!--        &lt;!&ndash;    推荐商品&ndash;&gt;-->
    <!--        <el-col :span="24">-->
    <!--          <el-card-->
    <!--              style="-->
    <!--              border: 1px solid #0080ff;-->
    <!--              box-shadow: 0px 4px 10px 0px rgba(225, 225, 225, 0.302);-->
    <!--            "-->
    <!--          >-->
    <!--            <div style="display: flex">-->
    <!--              <div class="borderHeader">-->
    <!--                <i class="el-icon-office-building" style="margin-right: 5px"></i-->
    <!--                >走进名校-->
    <!--              </div>-->
    <!--              &lt;!&ndash;              <span style="float: right;color: #666666;font-size: 15px">&ndash;&gt;-->
    <!--              &lt;!&ndash;              <span @click="findSchool('')">全部</span>&ndash;&gt;-->
    <!--              <div style="position: absolute; right: 1%; top: 15px">-->
    <!--                <el-menu-->
    <!--                    :default-active="activeIndex"-->
    <!--                    mode="horizontal"-->
    <!--                    style="border-bottom: none"-->
    <!--                >-->
    <!--                  <el-menu-item index="0" @click="findSchool('')"-->
    <!--                  >全部-->
    <!--                  </el-menu-item>-->
    <!--                  <el-menu-item-->
    <!--                      v-for="(item, index) in school.sysAddressList"-->
    <!--                      @click="findSchool(item.id)"-->
    <!--                      :index="item.id"-->
    <!--                  >{{ item.address }}-->
    <!--                  </el-menu-item>-->
    <!--                  <el-menu-item index="-1" @click="goToSchool">-->
    <!--                    更多...-->
    <!--                  </el-menu-item>-->
    <!--                </el-menu>-->
    <!--              </div>-->

    <!--              &lt;!&ndash;              <span v-for="item in school.sysAddressList" @click="findSchool(item.id)">&ndash;&gt;-->
    <!--              &lt;!&ndash;                {{ item.address }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                </span>&ndash;&gt;-->
    <!--              &lt;!&ndash;                            <span>更多···</span>&ndash;&gt;-->
    <!--              &lt;!&ndash;              </span>&ndash;&gt;-->
    <!--            </div>-->

    <!--            <el-divider></el-divider>-->

    <!--            <div-->
    <!--                v-if="-->
    <!--                school.sysFriendshipLinks &&-->
    <!--                school.sysFriendshipLinks.length > 0-->
    <!--              "-->
    <!--                style="margin-bottom: 15px"-->
    <!--            >-->
    <!--              <swiper-drag :dragList="school.sysFriendshipLinks"></swiper-drag>-->
    <!--            </div>-->

    <!--            <div style="display: flex; width: 100%">-->
    <!--              &lt;!&ndash; <el-row :gutter="10"> &ndash;&gt;-->
    <!--              &lt;!&ndash; <el-col :span="11" style="margin-left: 10px"> &ndash;&gt;-->
    <!--              <div style="flex: 1">-->
    <!--                <div v-for="item in school.sysFriendshipLinksLeft">-->
    <!--                  <i-->
    <!--                      class="el-icon-document-copy"-->
    <!--                      style="margin-left: 3px; color: #2f77f1"-->
    <!--                  ></i>-->
    <!--                  <el-link>-->
    <!--                    <span-->
    <!--                        style="-->
    <!--                        font-size: 15px;-->
    <!--                        line-height: 2;-->
    <!--                        margin-left: 5px;-->
    <!--                        color: #3d3d3d;-->
    <!--                      "-->
    <!--                        @click="openNewUlr(item.hyperlink)"-->
    <!--                    >{{ item.name }}</span-->
    <!--                    >-->
    <!--                  </el-link>-->
    <!--&lt;!&ndash;                  <span&ndash;&gt;-->
    <!--&lt;!&ndash;                      style="&ndash;&gt;-->
    <!--&lt;!&ndash;                      float: right;&ndash;&gt;-->
    <!--&lt;!&ndash;                      line-height: 2;&ndash;&gt;-->
    <!--&lt;!&ndash;                      color: #5eacf9;&ndash;&gt;-->
    <!--&lt;!&ndash;                      margin-right: 17px;&ndash;&gt;-->
    <!--&lt;!&ndash;                      font-size: 12px;&ndash;&gt;-->
    <!--&lt;!&ndash;                    "&ndash;&gt;-->
    <!--&lt;!&ndash;                  >{{ item.mmDdTime }}</span&ndash;&gt;-->
    <!--&lt;!&ndash;                  >&ndash;&gt;-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              &lt;!&ndash; </el-col> &ndash;&gt;-->

    <!--              &lt;!&ndash; <el-col :span="12" style="margin-left: 10px"> &ndash;&gt;-->
    <!--              <div style="flex: 1; margin-left: 15px">-->
    <!--                <div v-for="item in school.sysFriendshipLinksRight">-->
    <!--                  <i-->
    <!--                      class="el-icon-document-copy"-->
    <!--                      style="margin-left: 21px; color: #2f77f1"-->
    <!--                  ></i>-->
    <!--                  <el-link>-->
    <!--                    <span-->
    <!--                        style="-->
    <!--                        font-size: 15px;-->
    <!--                        line-height: 2;-->
    <!--                        margin-left: 5px;-->
    <!--                        color: #3d3d3d;-->
    <!--                      "-->
    <!--                        @click="openNewUlr(item.hyperlink)"-->
    <!--                    >{{ item.name }}</span-->
    <!--                    >-->
    <!--                  </el-link>-->
    <!--&lt;!&ndash;                  <span&ndash;&gt;-->
    <!--&lt;!&ndash;                      style="&ndash;&gt;-->
    <!--&lt;!&ndash;                      float: right;&ndash;&gt;-->
    <!--&lt;!&ndash;                      line-height: 2;&ndash;&gt;-->
    <!--&lt;!&ndash;                      color: #5eacf9;&ndash;&gt;-->
    <!--&lt;!&ndash;                      font-size: 12px;&ndash;&gt;-->
    <!--&lt;!&ndash;                    "&ndash;&gt;-->
    <!--&lt;!&ndash;                  >{{ item.mmDdTime }}</span&ndash;&gt;-->
    <!--&lt;!&ndash;                  >&ndash;&gt;-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              &lt;!&ndash; </el-col> &ndash;&gt;-->
    <!--              &lt;!&ndash; </el-row> &ndash;&gt;-->
    <!--            </div>-->
    <!--          </el-card>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {getFileType, getFriendship, getHomeImg,} from "/src/api/front/home";
import swiper from "../../component/swiper/index";
import swiperDrag from "../../component/swiper/swiperToDrag";
import news from "../../component/news";
import circulars from "../../component/circulars";

export default {
  name: "Home",
  components: {
    swiper,
    swiperDrag,
    news,
    circulars
  },
  data() {
    return {
      // 顶部左侧
      fileTypes: [],
      // 中间图片 三张
      middleImg: [],
      // 右侧图片 两张
      rightImg: [],
      // 底部走进名校
      school: [
        {
          sysAddressList: [],
          sysFriendshipLinks: [],
          sysFriendshipLinksLeft: [],
          sysFriendshipLinksRight: [],
        },
      ],
      activeIndex: "0",
    };
  },
  created() {
    this.load();
  },
  methods: {
    // 跳转到试题界面
    jumpFile(id) {
      const url = this.$router.resolve({path: "/front/topic/all", query: {id: id}});
      window.open(url.href, '_blank');
    },
    openNewUlr(url) {
      window.open(url);
    },
    findSchool(id) {
      getFriendship(id).then((res) => {
        this.school = res.data.data;
      });
    },
    load() {
      getFileType().then((res) => {
        this.fileTypes = res.data.data;
      });
      getHomeImg().then((res) => {
        this.middleImg = res.data.data.sysCarousels;
        this.rightImg = res.data.data.sysBroads;
      });
      getFriendship().then((res) => {
        this.school = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.borderHeader {
  color: #0080ff;
  font-size: 18px;
}

.center {
  .border {
    font-size: 18px;
    padding: 15px 15px 9px 15px;
    border-bottom: 1px solid #79bcff;
    background: #e1f0ff;
    color: #0080ff;
  }

  .more {
    float: right;
    font-size: 14px;
    color: #999999;
  }

  .step {
    position: relative;
  }

  .step:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5eacf9;
  }
}

::v-deep {
  .el-divider--horizontal {
    margin: 9px 0 24px 0;
    color: #d8d8d8;
  }

  .el-menu-item {
    padding: 0 5px;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 33px;
    line-height: 33px;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    color: #0080ff;
    border-bottom: 2px solid #0080ff;
  }

  .el-card.is-always-shadow,
  .el-card.is-hover-shadow:focus,
  .el-card.is-hover-shadow:hover {
    box-shadow: none;
  }

  .center {
    .el-card__body {
      padding: 0 0 15px 0;
    }
  }

  .footerBox {
    .el-card__body {
      padding: 15px;
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
