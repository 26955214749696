<template>
  <div style="text-align:center;
        color: #666666;
        font-size: 20px;
        width: 1100px;
">
    <el-carousel height="30px" direction="vertical" :autoplay="true" loop interval="2000">
      <el-carousel-item style="height: 100%" v-for="item in dateList" :key="item">
        <span class="medium" style="color: #2b2bb7">{{ item.title }}</span>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>

import {findCircularList} from "/src/api/front/home";


export default {
  name: "circulars",
  data() {
    return {
      dateList: [],
    }
  },
  created() {
    this.getCircularList();
  },
  methods: {
    getCircularList() {
      findCircularList().then(res => {
        this.dateList = res.data.data;
      })
    },
  }

};
</script>
