<template>
  <div class="lunbo-wrap" style="cursor: pointer">


    <div disabled="true">
      <el-carousel ref="carouselRef" @change="changeCarousel" arrow="never"
                   style="width: 510px;height: 320px; overflow: hidden" interval="10000" indicator-position="none">
        <el-carousel-item v-for="(item,index) in lunboList" :key="index">
          <el-image @click="openUrl(item)" style="width: 100%;height: 100%" :src="item.img"></el-image>
        </el-carousel-item>
      </el-carousel>

    </div>
    <!--轮播图-->
    <div style="display: flex">
      <div style="width:165px;height: 78px;margin: 5px 3px" v-for="(item,index) in lunboList" :key="index"
           @click="dian(item,index)"
           :class="index === current ?'borderW' : ''">
        <img style="width: 100%;height: 100%" :src="item.img" alt="">
      </div>

    </div>
    <!--    <div class="swiper-container">-->
    <!--      <div class="swiper-wrapper">-->
    <!--       -->


    <!--      </div>-->


    <!-- 如果需要导航按钮 -->
    <!--      <div class="swiper-button-prev"></div>-->
    <!--      <div class="swiper-button-next"></div>-->


  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'


export default {
  props: {
    lunboList: {type: Array}
  },
  components: {},
  name: 'TabAndBanner',
  data() {
    return {
      current: 0,
      isShow: false,
      swiperList: [],
      currentUrl: null
    }
  },

  methods: {
    openUrl(item){
      window.open(item.hyperlink)
    },
    dian(item, index) {
      this.current = index;
      this.currentUrl = this.lunboList[index].img;
      this.$refs.carouselRef.setActiveItem(index);
    },
    changeCarousel(active, val) {
      //active为进入幻灯片的索引值
      //val 为离开幻灯片的索引值
      this.current = active
    },


    carouselScroll() {
      var mySwiper = new Swiper('.swiper-container', {
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        //设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 自动轮播
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,


          //当用户滑动图片后继续自动轮播
        },
        slidesPerView: 3,
        spaceBetween: 15,
        //开启循环模式
        loop: false,
        observeParents: true,   //必须加入
        observer: true, //必须加入
      });
      if (this.lunboList.length > 0) {
        this.currentUrl = this.lunboList[0].img;
        this.current = 0
      }
    },

  },
  created() {
//l轮播图初始化函数carouselScroll一定要放在$nextTick回调中执行
    this.$nextTick(function () {
      this.carouselScroll();
    })
  }
}
</script>
<style lang="scss" scoped>
.swiper-slide {
  display: flex;
  cursor: pointer;

  img {
    width: 150px;
    height: 78px;
  }

  > p {
    width: 152px;

    font-size: 12px;
    color: #c1c1c1;
    font-weight: 400;
  }
}

.videoBoxTitle {
  font-size: 16px;
  color: #fff;
  margin: 14px 0;
  font-weight: 500;
}

.lunbo-wrap {
  //width: 1200px;
  //margin: 0 auto;
  .swiper-container {
    .swiper-button-prev, .swiper-button-next {
      top: 40%;
      color: #FFFFFF;
    }

    .swiper-slide {
      display: block;
      position: relative;
      cursor: pointer;

      img {
        width: 150px;
        height: 78px;
      }

      > p {
        width: 152px;

        font-size: 12px;
        color: #c1c1c1;
        font-weight: 400;
      }
    }

    .active {
      img {
        border: 2px solid #fd555f;
      }

      p {
        color: #fd555f;
      }
    }

    .swiper-pagination-bullet {
      width: 80px;
      height: 10px;
      border-radius: 0;
    }
  }
}

.borderW {
  border-style: solid;
  border-color: red;
  border-width: 2px;
}
</style>


